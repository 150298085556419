/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ReactLoader from "./components/loader";
import * as ROUTES from "./constants/routes";
import UserContext from "./context/user";
import useAuthListener from "./hooks/use-auth-listener";

import ProtectedRoute from "./helpers/protected-route";
import AppRoute from "./utils/AppRoute";
import LayoutDefault from "./layouts/LayoutDefault";
import { getRedirectLanguage } from "./utils/util_func";

const Login = lazy(() => import("./pages/login"));
const SignUp = lazy(() => import("./pages/sign-up"));
const Privacy = lazy(() => import("./pages/privacy"));
const Timeline = lazy(() => import("./pages/timeline"));
const TimelineEdit = lazy(() => import("./pages/timeline_edit"));
const Dashboard = lazy(() => import("./pages/dashboard"));
const ForumList = lazy(() => import("./pages/forum_list"));
const MeetingRegistrations = lazy(() => import("./pages/forum_enrolls"));
const Members = lazy(() => import("./pages/members"));
const MemberSetting = lazy(() => import("./pages/member_setting"));
const MemberRegHistory = lazy(() => import("./pages/member_enroll_history"));
const About = lazy(() => import("./pages/about"));
const AboutEdit = lazy(() => import("./pages/about-edit"));
const Lessons = lazy(() => import("./pages/forum_materials"));
const LessonsEdit = lazy(() => import("./pages/forum_materials_edit"));
const Announces = lazy(() => import("./pages/announce"));
const AnnouncesEdit = lazy(() => import("./pages/announce-edit"));
const Profile = lazy(() => import("./pages/profile"));
const UserRegistrations = lazy(() => import("./pages/user_enroll_list"));
const UserRegister = lazy(() => import("./pages/user_enroll"));
const Regulations = lazy(() => import("./pages/regulations"));
const RegulationsText = lazy(() => import("./pages/regulations_text"));
const NotFound = lazy(() => import("./pages/not-found"));
const JoinLine = lazy(() => import("./pages/join_line"));
const Apprentice = lazy(() => import("./pages/apprentice"));
const ApprenticeEdit = lazy(() => import("./pages/apprentice-edit"));
const HomeEnglish = lazy(() => import("./pages/home-en"));
const EbookReaderEn = lazy(() => import("./pages/ebook-reader-en"));
const EbookReader = lazy(() => import("./pages/ebook-reader"));

const ApprenticeRegulations = lazy(() =>
  import("./pages/apprentice_regulations")
);

export default function App() {
  const { user, setUser } = useAuthListener();
  const reload = () => window.location.reload();
  const lang = getRedirectLanguage();
  console.log(lang);
  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Router>
        <Suspense fallback={<ReactLoader />}>
          <Switch>
            <Route path={`${ROUTES.LOGIN}`} component={Login} />
            <Route path={ROUTES.SIGN_UP} component={SignUp} />
            <AppRoute
              user={user}
              path={ROUTES.USER_ENROLL_LIST}
              component={UserRegistrations}
              layout={LayoutDefault}
            />
            <AppRoute
              user={user}
              path={ROUTES.USER_ENROLL}
              component={UserRegister}
              layout={LayoutDefault}
            />
            <AppRoute
              user={user || { user: "anonymou" }}
              path={ROUTES.DAILY_POSTS}
              component={Timeline}
              layout={LayoutDefault}
            />
            <AppRoute
              user={user}
              path={ROUTES.DAILY_POSTS_EDIT}
              component={TimelineEdit}
              layout={LayoutDefault}
            />
            <AppRoute
              user={user || { user: "anonymou" }}
              path={ROUTES.PRIVACY}
              component={Privacy}
              layout={LayoutDefault}
            />
            <Route path={ROUTES.PROFILE} component={Profile} />
            <AppRoute
              exact
              user={user}
              path={ROUTES.FORUM_LIST}
              component={ForumList}
              layout={LayoutDefault}
            >
              <ForumList />
            </AppRoute>
            <AppRoute
              exact
              user={user}
              path={ROUTES.FORUM_ENROLLS}
              component={MeetingRegistrations}
              layout={LayoutDefault}
            >
              <MeetingRegistrations />
            </AppRoute>
            <AppRoute
              exact
              user={user}
              path={ROUTES.MEMBERS}
              component={Members}
              layout={LayoutDefault}
            >
              <Members />
            </AppRoute>
            <AppRoute
              exact
              user={user}
              path={ROUTES.MEMBER_SETTING}
              component={MemberSetting}
              layout={LayoutDefault}
            >
              <MemberSetting />
            </AppRoute>
            <AppRoute
              exact
              user={user}
              path={ROUTES.MEMBER_ENROLL_HISTORY}
              component={MemberRegHistory}
              layout={LayoutDefault}
            >
              <MemberRegHistory />
            </AppRoute>
            <AppRoute
              exact
              user={user}
              path={ROUTES.ABOUT}
              component={About}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              user={user}
              path={ROUTES.ABOUT_EDIT}
              component={AboutEdit}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              user={user}
              path={ROUTES.FORUM_MATERIALS}
              component={Lessons}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              user={user}
              path={ROUTES.FORUM_MATERIALS_EDIT}
              component={LessonsEdit}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              user={user}
              path={ROUTES.ANNOUNCES}
              component={Announces}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              user={user}
              path={ROUTES.ANNOUNCES_EDIT}
              component={AnnouncesEdit}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              user={user}
              path={ROUTES.REGULATIONS}
              component={Regulations}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              user={user}
              path={ROUTES.REGULATIONS_TEXT}
              component={RegulationsText}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              user={user}
              path={ROUTES.JOIN_LINE}
              component={JoinLine}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              user={user}
              path={ROUTES.APPRENTICE}
              component={Apprentice}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              user={user}
              path={ROUTES.APPRENTICE_EDIT}
              component={ApprenticeEdit}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              user={user}
              path={ROUTES.APPRENTICE_REGULATIONS}
              component={ApprenticeRegulations}
              layout={LayoutDefault}
            />
            <Route
              exact
              path={ROUTES.EBOOK_READER_EN}
              component={EbookReaderEn}
              layout={LayoutDefault}
            />
            <Route
              exact
              path={ROUTES.EBOOK_READER}
              component={EbookReader}
              layout={LayoutDefault}
            />
            <Route user={user} path="/zh" exact>
              <Dashboard />
            </Route>
            <Route user={user} path="/en" exact>
              <HomeEnglish />
            </Route>
            <Redirect
              to={{
                pathname: `/${lang}`,
              }}
            />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </UserContext.Provider>
  );
}
