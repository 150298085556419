/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
import { useState, useEffect, useContext } from "react";
// eslint-disable-next-line prettier/prettier
import { useHistory } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import FirebaseContext from "../context/firebase";
import { getUserByUserId } from "../services/firebase";
import * as ROUTES from "../constants/routes";

export default function useAuthListener() {
  const history = useHistory();

  const [user, setUser] = useState(
    JSON.parse(secureLocalStorage.getItem("authUser"))
  );
  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    const listener = firebase.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        // console.log('useAuthListener');
        // console.log(authUser);
        const userInfo = await getUserByUserId(authUser.uid);
        if (userInfo) {
          // we have a user...therefore we can store the user in localstorage
          secureLocalStorage.setItem("authUser", JSON.stringify(userInfo));
          setUser(userInfo);
        }
      } else {
        // we don't have an authUser, therefore clear the localstorage
        secureLocalStorage.removeItem("authUser");
        setUser(null);
      }
    });

    return () => listener();
  }, [firebase]);
  return { user, setUser };
}
