/* eslint-disable react/prop-types */
import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';

const LayoutDefault = ({ children }) => (
  <div className="bg-gray-background flex-column-center">
    <Header />
    {children}
    {/* <Footer /> */}
  </div>
);

export default LayoutDefault;
