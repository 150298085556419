/* eslint-disable prefer-destructuring */
/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import { Link } from "react-router-dom";

import "./styles.css";
import { useDetectOutsideClick } from "./useDetectOutsideClick";
/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-dropdown-menu-component-with-react-hooks
 */
export default function DropDown({ children, onChange, options, history }) {
  const dropdownRef = useRef(null);

  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = (e) => {
    e.preventDefault();
    setIsActive(!isActive);
  };
  const onLinkClick = (e) => {
    e.preventDefault();
    setIsActive(false);
    const idx = parseInt(e.target.getAttribute("idx"));
    onChange(idx, e.target.getAttribute("name"));
    const opt = options[idx];
    if (opt.to_link && opt.to_link.length > 0) {
      history.push(opt.to_link, opt.args);
    }
  };
  return (
    <div className="container">
      <div className="menu-container">
        <button
          type="button"
          onClick={onClick}
          className="flex items-center menu-trigger"
        >
          {children}
        </button>
        <nav
          ref={dropdownRef}
          className={`menu ${isActive ? "active" : "inactive"}`}
        >
          <ul>
            {options.map((option, index) => (
              <li>
                <Link className="flex items-center"
                  name={option.value} idx={index}
                  to={option.to_link ? option.to_link : ""}
                  onClick={onLinkClick}
                >
                  <div >{option.icon}</div>
                  <div>{option.title}</div>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}
