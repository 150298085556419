import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../constants/routes';

const AppRoute = ({ user, location, layout, component: Component, children, ...rest }) => {
  const Layout = layout === undefined ? (props) => <>{props.children}</> : layout;
  if (user) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  }
  return (
    <Redirect
      to={{
        pathname: ROUTES.LOGIN,
        state: { from: location }
      }}
    />
  );
};

export default AppRoute;

AppRoute.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  layout: PropTypes.func,
  component: PropTypes.object,
  children: PropTypes.object
};
