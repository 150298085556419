import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './logo';
// import FooterNav from './partials/FooterNav';
// import FooterSocial from './partials/FooterSocial';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
};

const Footer = ({ topOuterDivider, topDivider, ...props }) => (
  <footer {...props}>
    <div className="container">
      <div>
        <div className="footer-top space-between text-xxs">
          <Logo />
          {/* <FooterSocial /> */}
        </div>
        <div className="footer-bottom space-between text-xxs invert-order-desktop">
          {/* <FooterNav /> */}
          <div className="footer-copyright">社團法人中華大安修德學會 製作</div>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
