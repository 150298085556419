import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyAO_AN0LDGxHib3zCpEhIAEWgiXEvVX4dk',
  authDomain: 'daian-281b2.firebaseapp.com',
  databaseURL: 'https://daian-281b2.firebaseio.com',
  projectId: 'daian-281b2',
  storageBucket: 'daian-281b2.appspot.com',
  messagingSenderId: '926772866587',
  appId: '1:926772866587:web:ed63b1e29ed8274b8a52be'
};

const firebase = Firebase.initializeApp(config);

const { FieldValue } = Firebase.firestore;

export { firebase, FieldValue };
