/* eslint-disable global-require */
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from './elements/Image';

// eslint-disable-next-line react/prop-types
const Logo = ({ className, ...props }) => {
  const classes = classNames('brand', className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to="/">
          <Image src={require('../assets/images/icon.png')} alt="Open" width={64} height={64} />
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
