/* eslint-disable prettier/prettier */
/* eslint-disable quotes */
export const DASHBOARD = "/";
export const LOGIN = "/login";
export const SIGN_UP = "/sign-up";
export const PRIVACY = "/privacy";
export const DAILY_POSTS = "/daily_posts";
export const DAILY_POSTS_EDIT = "/daily_posts_edit";
export const FORUM_LIST = "/forum_list";
export const FORUM_ENROLLS = "/forum_enrolls";
export const MEMBERS = "/members";
export const MEMBER_SETTING = "/member_setting";
export const MEMBER_ENROLL_HISTORY = "/member_enroll_history";
export const ABOUT = "/about";
export const ABOUT_EDIT = "/about-edit";
export const JOIN_LINE = "/join_line";
export const FORUM_MATERIALS = "/forum_materials";
export const FORUM_MATERIALS_EDIT = "/forum_materials_edit";
export const ANNOUNCES = "/announces";
export const ANNOUNCES_EDIT = "/announces-edit";
export const REGULATIONS = "/regulations";
export const REGULATIONS_TEXT = "/regulations_text";
export const PROFILE = "/p/:username";
export const NOT_FOUND = "/not-found";
export const USER_ENROLL_LIST = "/user_enroll_list";
export const USER_ENROLL = "/user_enroll";
export const APPRENTICE = "/apprentice";
export const APPRENTICE_EDIT = "/apprentice-edit";
export const APPRENTICE_REGULATIONS = "/apprentice_regulations";
export const EBOOK_READER_EN = "/en/ebook_reader";
export const EBOOK_READER = "/zh/ebook_reader";
