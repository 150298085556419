/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import secureLocalStorage from "react-secure-storage";
import { firebase, FieldValue } from "../lib/firebase";
import { getDateString } from "../utils/util_func";
// const { firebase } = useContext(FirebaseContext);

export async function checkToFlushLocalStorage() {
  if (secureLocalStorage.getItem("meetings")) {
    secureLocalStorage.removeItem("meetings");
  }
}

export async function login(loginId, loginPassword) {
  const body = JSON.stringify({
    userId: loginId,
    password: loginPassword,
    fcmToken: "",
  });
  const headers = new Headers();
  console.log(body);
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  const response = await fetch(
    "https://us-central1-daian-281b2.cloudfunctions.net/getUserTokenForWeb",
    {
      mode: "cors",
      method: "POST",
      headers,
      body,
    }
  );
  console.log(response);
  console.log(response.body);
  const json = await response.json();
  console.log(json);
  if (json.returnCode !== 0) {
    throw new Error(json.reason);
  }
  // if (json.role !== 'admin' && !(json.disciple && json.disciple.length > 0)) {
  //   console.log(json);
  //   throw new Error('目前尚不支援學員登入');
  // }
  const tokenResponse = await firebase.auth().signInWithCustomToken(json.token);
  checkToFlushLocalStorage();
  return tokenResponse;
}

export async function doesUsernameExist(username) {
  const result = await firebase
    .firestore()
    .collection("users")
    .where("username", "==", username.toLowerCase())
    .get();

  return result.docs.length > 0;
}

export async function getUserByUsername(username) {
  const result = await firebase
    .firestore()
    .collection("users")
    .where("username", "==", username.toLowerCase())
    .get();

  return result.docs.map((item) => ({
    ...item.data(),
    docId: item.id,
  }));
}

// get user from the firestore where userId === userId (passed from the auth)
export async function getUserByUserId(userId) {
  const doc = await firebase
    .firestore()
    .collection("members")
    .doc(userId)
    .get();
  if (doc.exists) {
    return {
      ...doc.data(),
      id: doc.id,
      docId: doc.id,
    };
  }
  return null;
}

// check all conditions before limit results
export async function getSuggestedProfiles(userId, following) {
  let query = firebase.firestore().collection("users");

  if (following.length > 0) {
    query = query.where("userId", "not-in", [...following, userId]);
  } else {
    query = query.where("userId", "!=", userId);
  }
  const result = await query.limit(10).get();

  const profiles = result.docs.map((user) => ({
    ...user.data(),
    docId: user.id,
  }));

  return profiles;
}

export async function updateLoggedInUserFollowing(
  loggedInUserDocId, // currently logged in user document id (karl's profile)
  profileId, // the user that karl requests to follow
  isFollowingProfile // true/false (am i currently following this person?)
) {
  return firebase
    .firestore()
    .collection("users")
    .doc(loggedInUserDocId)
    .update({
      following: isFollowingProfile
        ? FieldValue.arrayRemove(profileId)
        : FieldValue.arrayUnion(profileId),
    });
}

export async function updateFollowedUserFollowers(
  profileDocId, // currently logged in user document id (karl's profile)
  loggedInUserDocId, // the user that karl requests to follow
  isFollowingProfile // true/false (am i currently following this person?)
) {
  return firebase
    .firestore()
    .collection("users")
    .doc(profileDocId)
    .update({
      followers: isFollowingProfile
        ? FieldValue.arrayRemove(loggedInUserDocId)
        : FieldValue.arrayUnion(loggedInUserDocId),
    });
}

export async function getPhotos(userId, lastUpdateTime, count) {
  let result;
  console.log(lastUpdateTime);
  if (lastUpdateTime === null) {
    result = await firebase
      .firestore()
      .collection("news")
      .orderBy("create_time", "desc")
      .limit(count)
      .get();
  } else {
    result = await firebase
      .firestore()
      .collection("news")
      .orderBy("create_time", "desc")
      .where("create_time", "<", lastUpdateTime)
      // .startAfter(lastUpdateTime)
      .limit(count)
      .get();
  }
  const userFollowedPhotos = result.docs.map((doc) => {
    const item = doc.data();
    return {
      ...item,
      docId: doc.id,
      file_urls: item.file_url ? [item.file_url] : [],
      show_update_time:
        item.create_time && item.create_time._seconds
          ? new Date((item.create_time._seconds + 3600 * 8) * 1000)
              .toISOString()
              .slice(0, 11)
              .replace("T", " ")
          : item.create_time && item.create_time.seconds
          ? new Date((item.create_time.seconds + 3600 * 8) * 1000)
              .toISOString()
              .slice(0, 11)
              .replace("T", " ")
          : "",
    };
  });

  const photosWithUserDetails = await Promise.all(
    userFollowedPhotos.map(async (photo) => {
      let userLikedPhoto = false;
      if (photo.likes && photo.likes.includes(userId)) {
        userLikedPhoto = true;
      }
      // const user = await getUserByUserId(photo.update_by);
      // const { userName } = user[0];
      const userName = "unknown";
      return { userName, ...photo, userLikedPhoto };
    })
  );

  return photosWithUserDetails;
}
export async function getAbouts(userId, lastUpdateTime, count) {
  let result;
  if (lastUpdateTime === null) {
    result = await firebase
      .firestore()
      .collection("abouts")
      .orderBy("update_time", "desc")
      .limit(count)
      .get();
  } else {
    result = await firebase
      .firestore()
      .collection("abouts")
      .orderBy("update_time", "desc")
      .startAfter(lastUpdateTime)
      .limit(count)
      .get();
  }
  const result1 = result.docs.map((doc) => {
    const item = doc.data();
    return {
      ...item,
      docId: doc.id,
      show_update_time:
        item.update_time && item.update_time._seconds
          ? new Date((item.update_time._seconds + 3600 * 8) * 1000)
              .toISOString()
              .slice(0, 11)
              .replace("T", " ")
          : item.update_time && item.update_time.seconds
          ? new Date((item.update_time.seconds + 3600 * 8) * 1000)
              .toISOString()
              .slice(0, 11)
              .replace("T", " ")
          : "",
    };
  });
  return result1;
}
export async function getUserPhotosByUserId(userId) {
  const result = await firebase
    .firestore()
    .collection("photos")
    .where("userId", "==", userId)
    .get();

  const photos = result.docs.map((photo) => ({
    ...photo.data(),
    docId: photo.id,
  }));
  return photos;
}

export async function isUserFollowingProfile(
  loggedInUserUsername,
  profileUserId
) {
  const result = await firebase
    .firestore()
    .collection("users")
    .where("username", "==", loggedInUserUsername) // karl (active logged in user)
    .where("following", "array-contains", profileUserId)
    .get();

  const [response = {}] = result.docs.map((item) => ({
    ...item.data(),
    docId: item.id,
  }));

  return response.userId;
}

export async function toggleFollow(
  isFollowingProfile,
  activeUserDocId,
  profileDocId,
  profileUserId,
  followingUserId
) {
  // 1st param: karl's doc id
  // 2nd param: raphael's user id
  // 3rd param: is the user following this profile? e.g. does karl follow raphael? (true/false)
  await updateLoggedInUserFollowing(
    activeUserDocId,
    profileUserId,
    isFollowingProfile
  );

  // 1st param: karl's user id
  // 2nd param: raphael's doc id
  // 3rd param: is the user following this profile? e.g. does karl follow raphael? (true/false)
  await updateFollowedUserFollowers(
    profileDocId,
    followingUserId,
    isFollowingProfile
  );
}

async function queryDataRepository(
  collectionName,
  extraDataCallback,
  sortFunc
) {
  let newLastUpdateTime = null;
  if (
    secureLocalStorage.getItem(collectionName) &&
    secureLocalStorage.getItem(`${collectionName}_lastUpdatedAt`)
  ) {
    console.log(`query ${collectionName} From LocalStorage`);
    const updatedResult = [];
    const lastUpdateTime = parseInt(
      secureLocalStorage.getItem(`${collectionName}_lastUpdatedAt`)
    );
    console.log(lastUpdateTime);
    console.log(new Date(lastUpdateTime));
    const queryNewUpdates = await firebase
      .firestore()
      .collection(collectionName)
      .where("updatedAt", ">", new Date(lastUpdateTime))
      .orderBy("updatedAt", "desc")
      .get();
    console.log(queryNewUpdates.docs);
    // eslint-disable-next-line no-restricted-syntax
    for await (const doc of queryNewUpdates.docs) {
      const data = doc.data();
      const extraData =
        extraDataCallback != null ? extraDataCallback(data) : {};
      updatedResult.push({
        ...data,
        ...extraData,
        id: doc.id,
      });
    }
    if (queryNewUpdates.docs.length > 0) {
      newLastUpdateTime = queryNewUpdates.docs[0].data().updatedAt;
    }
    console.log(lastUpdateTime);
    console.log(updatedResult);
    const localData = JSON.parse(secureLocalStorage.getItem(collectionName));
    if (updatedResult.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const newObj of updatedResult) {
        const foundIndex = localData.findIndex((x) => x.id === newObj.id);
        if (foundIndex >= 0) {
          localData[foundIndex] = newObj;
        } else {
          localData.push(newObj);
        }
      }
      if (sortFunc) localData.sort(sortFunc);
    }
    const queryDeleted = await firebase
      .firestore()
      .collection("collections_deleted")
      .where("collection", "==", collectionName)
      .where("deletedAt", ">", new Date(lastUpdateTime))
      .orderBy("deletedAt", "desc")
      .get();
    console.log(queryDeleted.docs);
    if (queryDeleted.docs.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const deletedObj of queryDeleted.docs) {
        const foundIndex = localData.findIndex((x) => x.id === deletedObj.id);
        if (foundIndex >= 0) {
          localData.splice(foundIndex, 1);
        }
      }
      newLastUpdateTime = queryDeleted.docs[0].data().deletedAt;
    }
    console.log(localData);
    secureLocalStorage.setItem(collectionName, JSON.stringify(localData));
    if (newLastUpdateTime) {
      secureLocalStorage.setItem(
        `${collectionName}_lastUpdatedAt`,
        newLastUpdateTime.seconds * 1000 +
          newLastUpdateTime.nanoseconds / 1000000 +
          1
      );
    }
    return localData;
  }
  console.log(`query ${collectionName} From FireStore`);
  const querySnapshot = await firebase
    .firestore()
    .collection(collectionName)
    .get();

  const result = [];
  querySnapshot.forEach((doc) => {
    const entity = doc.data();
    try {
      const extraData =
        extraDataCallback != null ? extraDataCallback(entity) : {};
      result.push({
        ...entity,
        ...extraData,
        id: doc.id,
      });
      if (newLastUpdateTime == null && entity.updatedAt != null) {
        newLastUpdateTime = entity.updatedAt;
      } else if (
        newLastUpdateTime != null &&
        entity.updatedAt != null &&
        newLastUpdateTime < entity.updatedAt
      ) {
        newLastUpdateTime = entity.updatedAt;
      }
    } catch (err) {
      console.log(entity.subject);
      console.log(err);
    }
  });
  if (sortFunc) result.sort(sortFunc);
  secureLocalStorage.setItem(collectionName, JSON.stringify(result));
  if (newLastUpdateTime != null) {
    secureLocalStorage.setItem(
      `${collectionName}_lastUpdatedAt`,
      newLastUpdateTime.seconds * 1000 +
        newLastUpdateTime.nanoseconds / 1000000 +
        1
    );
  }
  return result;
}
export async function getAllMeetings() {
  return queryDataRepository(
    "meetings",
    (data) => ({ meetingDateString: getDateString(data.meetingDate) }),
    (a, b) => {
      if (a.meetingDateString === b.meetingDateString) return 0;
      return a.meetingDateString > b.meetingDateString ? -1 : 1;
    }
  );
}

export async function getLessons(lastCreateTime, count) {
  let result;
  if (lastCreateTime === null) {
    result = await firebase
      .firestore()
      .collection("lessons")
      .orderBy("create_time", "desc")
      .limit(count)
      .get();
  } else {
    result = await firebase
      .firestore()
      .collection("lessons")
      .where("create_time", "<", lastCreateTime)
      .orderBy("create_time", "desc")
      // .startAfter(lastCreateTime)
      .limit(count)
      .get();
  }
  const result1 = result.docs.map((doc) => {
    const item = doc.data();
    return {
      ...item,
      docId: doc.id,
      show_update_time:
        item.create_time && item.create_time._seconds
          ? new Date((item.create_time._seconds + 3600 * 8) * 1000)
              .toISOString()
              .slice(0, 11)
              .replace("T", " ")
          : item.create_time && item.create_time.seconds
          ? new Date((item.create_time.seconds + 3600 * 8) * 1000)
              .toISOString()
              .slice(0, 11)
              .replace("T", " ")
          : "",
    };
  });
  return result1;
}

export async function getAnnounces(lastCreateTime, count) {
  let result;
  if (lastCreateTime === null) {
    result = await firebase
      .firestore()
      .collection("announce")
      .orderBy("create_time", "desc")
      .limit(count)
      .get();
  } else {
    result = await firebase
      .firestore()
      .collection("announce")
      .orderBy("create_time", "desc")
      .startAfter(lastCreateTime)
      .limit(count)
      .get();
  }
  const result1 = result.docs.map((doc) => {
    const item = doc.data();
    return {
      ...item,
      docId: doc.id,
      show_update_time:
        item.create_time && item.create_time._seconds
          ? new Date((item.create_time._seconds + 3600 * 8) * 1000)
              .toISOString()
              .slice(0, 11)
              .replace("T", " ")
          : item.create_time && item.create_time.seconds
          ? new Date((item.create_time.seconds + 3600 * 8) * 1000)
              .toISOString()
              .slice(0, 11)
              .replace("T", " ")
          : "",
    };
  });
  return result1;
}

export async function getApprentice(lastCreateTime, count) {
  let result;
  if (lastCreateTime === null) {
    result = await firebase
      .firestore()
      .collection("apprentice")
      .orderBy("update_time", "desc")
      .limit(count)
      .get();
  } else {
    result = await firebase
      .firestore()
      .collection("apprentice")
      .orderBy("update_time", "desc")
      .startAfter(lastCreateTime)
      .limit(count)
      .get();
  }
  const result1 = result.docs.map((doc) => {
    const item = doc.data();
    return {
      ...item,
      docId: doc.id,
      show_update_time:
        item.create_time && item.create_time._seconds
          ? new Date((item.create_time._seconds + 3600 * 8) * 1000)
              .toISOString()
              .slice(0, 11)
              .replace("T", " ")
          : item.create_time && item.create_time.seconds
          ? new Date((item.create_time.seconds + 3600 * 8) * 1000)
              .toISOString()
              .slice(0, 11)
              .replace("T", " ")
          : "",
    };
  });
  return result1;
}

export const updateRegistration = async (reg, admin) => {
  const token = await firebase.auth().currentUser.getIdToken(true);
  console.log(`userToken: ${token}`);
  console.log(reg);
  const body = JSON.stringify({
    admin: admin || false,
    reg: {
      id: reg.id,
      meeting_id: reg.meeting_id,
      name: reg.name,
      phone: reg.phone,
      items: reg.items,
      register_status: reg.register_status,
    },
  });
  const response = await fetch(
    "https://us-central1-daian-281b2.cloudfunctions.net/update_registration",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body,
    }
  );
  console.log(response);
  const respJson = await response.json();
  return respJson;
};
