/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import FirebaseContext from "../context/firebase";
import UserContext from "../context/user";
import * as ROUTES from "../constants/routes";
import { DEFAULT_IMAGE_PATH } from "../constants/paths";
import DropDown from "./dropdown-menu/dropdown";
import "../styles/myApp.css";
import { getRedirectLanguage } from "../utils/util_func";

export default function Header() {
  const { user } = useContext(UserContext);
  const { firebase } = useContext(FirebaseContext);
  const history = useHistory();
  const lang = getRedirectLanguage();

  const meetingOptions = [
    {
      title: "報名共修會課程",
      value: "user_enroll_list",
      to_link: ROUTES.USER_ENROLL_LIST,
      icon: <i className="bi-card-list mr-1" />,
    },
    // {
    //   title: '推薦親友',
    //   value: 'about',
    //   to_link: '/forum_list',
    //   icon: <i className="bi-person-plus mr-2" />
    // }
  ];
  const infoOptions = [
    {
      title: "共修會課程內容",
      value: "forum_materials",
      to_link: "/forum_materials",
      icon: <i className="bi-file-richtext mr-1" />,
    },
    {
      title: "學會公告",
      value: "announces",
      to_link: "/announces",
      icon: <i className="bi-card-list mr-1" />,
    },
    {
      title: "會員參學規範",
      value: "member_rules",
      to_link: ROUTES.REGULATIONS_TEXT,
      icon: <i className="bi-exclamation-triangle mr-1" />,
    },
    // {
    //   title: '關於學會',
    //   value: 'about',
    //   to_link: ROUTES.ABOUT,
    //   icon: <i className="bi-info mr-2" />
    // }
  ];
  const attend_2023 =
    user && user.attend_count_2023 ? user.attend_count_2023 : 0;
  if (
    user &&
    (user.role === "admin" ||
      (user.disciple && user.disciple.length > 0 && user.disciple < "9"))
  ) {
    infoOptions.push({
      title: "弟子專區",
      value: "apprentice",
      to_link: ROUTES.APPRENTICE,
      icon: <i className="bi-stars mr-1" />,
    });
  }
  if (
    user &&
    (user.role === "admin" ||
      (user.disciple && user.disciple.length > 0 && user.disciple < "9"))
  ) {
    infoOptions.push({
      title: "弟子參學規範",
      value: "apprentice",
      to_link: ROUTES.APPRENTICE_REGULATIONS,
      icon: <i className="bi-star mr-1" />,
    });
  }
  if (
    user &&
    (user.role === "admin" || user.attend_count_2022 + attend_2023 >= 3)
  ) {
    infoOptions.push({
      title: "加入LINE社群",
      value: "join_line",
      to_link: ROUTES.JOIN_LINE,
      icon: <img src="/images/line_icon.png" alt="" className="w-4 h-4 mr-1" />,
    });
  }
  const adminOptions = [
    {
      title: "管理共修會課程報名",
      value: "forum_list",
      to_link: "/forum_list",
      icon: <i className="bi-card-list mr-1" />,
    },
    {
      title: "管理會員資料",
      value: "member_list",
      to_link: "/members",
      icon: <i className="bi-person-lines-fill mr-1" />,
    },
    // {
    //   title: '授權新會員',
    //   value: 'new_member',
    //   icon: <i className="bi-person-check mr-2" style={{ color: 'green' }} />
    // }
  ];

  const userOptions = [
    {
      title: "顯示個人出席紀錄",
      value: "attend_history",
      to_link: ROUTES.MEMBER_ENROLL_HISTORY,
      icon: <i className="bi-card-list mr-1" />,
    },
    {
      title: "設定個人資料",
      value: "user_setting",
      to_link: "/member_setting",
      args: { memberId: user ? user.id : "", adminMode: false },
      icon: <i className="bi-gear mr-1" />,
    },
    {
      title: <span style={{ color: "red" }}>登出</span>,
      value: "logout",
      icon: <i className="bi-door-open mr-1" style={{ color: "red" }} />,
    },
  ];
  const onSelect = (idx, value) => {
    switch (value) {
      case "logout":
        firebase.auth().signOut();
        history.push(ROUTES.DASHBOARD);
        break;
      default:
        break;
    }
  };
  const languageOptions = [
    {
      title: "繁體中文",
      value: "zh",
      icon: <img src="/images/zh.png" alt="" className="w-6 h-6 mr-1" />,
    },
    {
      title: "English",
      value: "en",
      icon: <img src="/images/en.png" alt="" className="w-6 h-6 mr-1" />,
    },
  ];
  const onLangSelect = (idx, value) => {
    history.push(`/${value}`);
  };
  return (
    <header className="flex justify-between h-1/6 bg-white border-b border-gray-primary p-2">
      <div className="text-gray-700 text-center flex items-center align-items cursor-pointer">
        <Link to={ROUTES.DASHBOARD}>
          <img src="/images/icon.png" alt="Logo" className="w-12" />
        </Link>
      </div>
      <div className="text-gray-700 text-center flex items-center align-items justify-between">
        <div className="flex items-center mr-2">
          <Link to={ROUTES.DAILY_POSTS} aria-label="Dashboard">
            <i className="bi-house-door" style={{ fontSize: "1.5rem" }} />
          </Link>
        </div>
        <div className="flex items-center mr-2">
          <Link to={ROUTES.EBOOK_READER} aria-label="Ebook">
            <i className="bi-book" style={{ fontSize: "1.5rem" }} />
          </Link>
        </div>
        {user ? (
          <>
            {user && (
              <div className="flex items-center mr-2">
                <DropDown
                  history={history}
                  options={meetingOptions}
                  onChange={onSelect}
                  placeholder="Select an option"
                  arrowClosed={<span className="arrow-closed" />}
                  arrowOpen={<span className="arrow-open" />}
                >
                  <i className="bi-calendar3" />
                </DropDown>
              </div>
            )}
            {user && (
              <div className="flex items-center mr-2">
                <DropDown
                  history={history}
                  options={infoOptions}
                  onChange={onSelect}
                  placeholder="Select an option"
                  arrowClosed={<span className="arrow-closed" />}
                  arrowOpen={<span className="arrow-open" />}
                >
                  <i className="bi-info-circle" />
                </DropDown>
              </div>
            )}
            {user && user.role === "admin" && (
              <div className="flex items-center mr-2">
                <DropDown
                  history={history}
                  options={adminOptions}
                  onChange={onSelect}
                  placeholder="Select an option"
                  arrowClosed={<span className="arrow-closed" />}
                  arrowOpen={<span className="arrow-open" />}
                >
                  <i className="bi-tools" />
                </DropDown>
              </div>
            )}
            {user && (
              <DropDown
                className="flex mr-2"
                history={history}
                options={userOptions}
                onChange={onSelect}
                placeholder="Select an option"
                arrowClosed={<span className="arrow-closed" />}
                arrowOpen={<span className="arrow-open" />}
              >
                <div className="flex items-center">
                  {/* <img
                    src={
                      user && user.avatar_url
                        ? user.avatar_url
                        : DEFAULT_IMAGE_PATH
                    }
                    alt="User avatar"
                    className="flex w-8 h-8"
                    onError={(e) => {
                      e.target.src = DEFAULT_IMAGE_PATH;
                    }}
                  /> */}
                  <span className="flex">
                    {user.discipleName ? user.discipleName : user.userName}
                  </span>
                </div>
              </DropDown>
            )}
          </>
        ) : (
          <>
            <Link to={ROUTES.DASHBOARD}>
              <button
                type="button"
                className="bg-blue-medium font-bold text-sm rounded text-white w-20 h-8 mr-2"
              >
                登入
              </button>
            </Link>
            {/* <Link to={ROUTES.SIGN_UP}>
              <button type="button" className="font-bold text-sm rounded text-blue-medium w-20 h-8">
                申請加入
              </button>
            </Link> */}
          </>
        )}
        <div className="flex items-center ml-2">
          <DropDown
            history={history}
            options={languageOptions}
            onChange={onLangSelect}
            placeholder="Select an option"
            arrowClosed={<span className="arrow-closed" />}
            arrowOpen={<span className="arrow-open" />}
          >
            <i className="bi-globe" />
          </DropDown>
        </div>
      </div>
    </header>
  );
}
