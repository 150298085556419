/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-prototype-builtins */
export const registerStatusName = (code) => {
  switch (code) {
    case 1:
      return "報名成功";
    case 2:
      return "待確認";
    case 3:
      return "已報到";
    case 4:
      return "請假";
    case 5:
      return "未出席";
    case 6:
      return "未報名出席";
    case 7:
      return "逾期取消";
    case 8:
      return "期限前取消";
    case 9:
      return "遲到";
    default:
      return "";
  }
};
export const registerStatusColor = (code) => {
  switch (code) {
    case 1:
      return "green";
    case 2:
      return "gray";
    case 3:
      return "blue";
    case 4:
      return "red";
    case 5:
      return "red";
    case 6:
      return "pink";
    default:
      return "red";
  }
};
export const meetingStatusName = (code) => {
  const statusName = {
    editing: "課程編輯中",
    open: "開始報名",
    closed: "結束報名",
    start: "課程開始",
    end: "課程結束",
  };
  return statusName[code];
};
export const meetingStatusColor = (code) => {
  const statusColor = {
    editing: "darkgray",
    open: "red",
    closed: "black",
    start: "green",
    end: "darkgray",
  };
  return statusColor[code];
};
export const compareObjects = (obj1, obj2) => {
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;

  if (obj1Length === obj2Length) {
    return Object.keys(obj1).every(
      (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
    );
  }
  return false;
};

export const getDateString = (inputDate) => {
  if (!inputDate) return "";
  return inputDate && inputDate._seconds
    ? new Date((inputDate._seconds + 3600 * 8) * 1000)
        .toISOString()
        .slice(0, 10)
    : inputDate.seconds
    ? new Date((inputDate + 3600 * 8) * 1000).toISOString().slice(0, 10)
    : new Date(inputDate).toISOString().slice(0, 10);
};

export const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "zh";
  }

  const lang =
    navigator && navigator.language && navigator.language.split("-")[0];
  if (!lang) return "zh";

  switch (lang) {
    case "en":
      return "en";
    default:
      return "zh";
  }
};
